@import '../../../../scss/theme-bootstrap';

.email-signup-panel {
  &__wrapper {
    display: flex;
    font-family: $font--text;
  }
  &__content-wrapper {
    flex: auto;
  }
  &__image {
    @include swap_direction(margin, 0 20px 0 0);
    max-width: 50%;
  }
  &__title {
    padding: 0 0 10px;
    font-weight: 500;
  }
  &__copy {
    font-family: $font--text;
    line-height: 1.2;
    p {
      line-height: 1.2;
    }
  }
  &__field {
    width: 100%;
    margin: 0 auto 20px;
  }
  &__fields {
    margin-bottom: 10px;
  }
  &__sms-terms-conditions--animated {
    display: none;
  }
  &__email-promotions,
  &__sms-terms-conditions {
    margin-bottom: 15px;
  }
  &__information {
    cursor: pointer;
    display: flex;
    align-items: center;
    border: none;
    background: none;
    color: $color-black;
    text-transform: inherit;
    letter-spacing: inherit;
    font-family: $font--text;
  }
  &__icon--information {
    float: $ldirection;
    fill: $color-black;
    width: 14px;
    height: 14px;
    margin-#{$rdirection}: 4px;
  }
  &__gdpr-overlay[role='dialog'] {
    display: none;
    position: absolute;
    text-decoration: none;
    padding: 20px;
    background-color: $color-black;
    color: $color-white;
    top: 40px;
    width: 100%;
    z-index: 2;
    align-items: flex-start;
    justify-content: space-between;
    @include breakpoint($landscape-up) {
      line-height: normal;
      #{$ldirection}: -73%;
      top: 0;
      width: 71%;
    }
    &.expanded {
      display: flex;
    }
  }
  &__gdpr-link {
    position: relative;
    margin-bottom: 1em;
  }
  &__gdpr-overlay-icon--close {
    fill: $color-black;
    width: 15px;
    height: 15px;
    margin-bottom: 0;
  }
  &__gdpr-overlay-close-btn {
    display: flex;
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
    order: 2;
  }
  &__gdpr-overlay-copy {
    order: 1;
  }
  &__gdpr-link-copy {
    &:hover ~ .email-signup-panel__gdpr-overlay[role='dialog'] {
      display: flex;
      border: 1px solid $color-black;
      color: $color-black;
      padding: 20px;
    }
  }
  &__learn-more {
    margin: 0 auto 20px;
  }
  &__submit {
    margin: 0 auto 16px;
    width: 100%;
  }
  &__message--success {
    color: $color-success;
  }
  &__message--error {
    color: $color-error;
  }
}
